.profileCardContaier {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  height: 423px;
  background-color: white;
  border-radius: 1ch;
  margin: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.profileCardContaier .topRectangle {
  background-color: var(--blue);
  width: 100%;
  height: calc(0.4*423px);
  border-radius: 1ch 1ch 0ch 0ch;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.profileCardContaier .profileImage {
  margin-top: 5%;
  height: 190px;
  width: 190px;
  border: 0.5ch;
  border-style: solid;
  border-color: var(--blue);
  border-radius: 100%;
  object-fit: cover;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.3);
}

.profileCardContaier .cardInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileCardContaier .name {
  font-weight: 700;
  font-size: 30px;
  margin-top: 80px;
  margin-bottom: 0px;
}

.profileCardContaier .link {
  height: 40px;
  width: 40px;
  margin: 5px 10px 30px 10px;
  border-radius: 100%;
  cursor: pointer;
}

.profileCardContaier .bottomText {
  margin: 10px;
  font-size: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
}

.profileCardContaier .bottomText p {
  margin: 3px;
  margin-top: 5px;
}

.profileCardContaier .programmeContainer {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  justify-content: center;
}

.profileCardContaier .programmeStart {
  font-weight: 700;
  margin-right: 5px;
}

@media (max-width: 600px) {
  .profileCardContaier {
    width: calc(450px * 0.9);
    height: calc(423px * 0.9);
  }

  .profileCardContaier .profileImage {
    margin-top: 10%;
    height: calc(190px * 0.9);
    width: calc(190px * 0.9);
  }

  .profileCardContaier .cardInfo {
    transform: scale(0.9);
  }

  .profileCardContaier .name {
    margin-top: 60px;
    margin-bottom: 7px;
  }
}

@media (max-width: 500px) {
  .profileCardContaier {
    width: calc(450px * 0.8);
    height: calc(423px * 0.9);
  }

  .profileCardContaier .profileImage {
    margin-top: 10%;
  }

  .profileCardContaier .cardInfo {
    transform: scale(0.9);
  }

  .profileCardContaier .name {
    margin-top: 40px;
    margin-bottom: 5px;
  }
}

@media (max-width: 400px) {
  .profileCardContaier {
    width: calc(450px * 0.7);
    height: calc(423px * 0.8);
  }

  .profileCardContaier .profileImage {
    margin-top: 11%;
  }

  .profileCardContaier .cardInfo {
    transform: scale(0.8);
  }
}

@media (max-width: 359px) {
  .profileCardContaier {
    width: calc(450px * 0.6);
    height: calc(423px * 0.7);
  }

  .profileCardContaier .profileImage {
    margin-top: 20%;
  }

  .profileCardContaier .cardInfo {
    transform: scale(0.6);
  }
}