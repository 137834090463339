.AboutUsContainer {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--beige);
    padding-top: 100px;
    padding-bottom: 100px;
}

.AboutUsContainer .topImage {
    position: absolute;
    width: 100vw;
    top: -20px
}

.AboutUsContainer .profileCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.AboutUsContainer .title {
    margin-bottom: 30px;
}

.AboutUsContainer .aboutUsText {
    width: 950px;
    text-align: center;
    font-size: 22px;
    margin-bottom: 40px;
}

@media (max-width: 1000px) {
    .AboutUsContainer .topImage {
        top: 40px
    }

    .AboutUsContainer .aboutUsText {
        font-size: 1.2rem;
        width: 80vw;
    }

}

@media (max-width: 600px) {
    .AboutUsContainer .title {
        font-size: 3rem
    }
}