.GameRoundContainer {
  height: calc(100vh - 66.5px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(../images/GameRoundBackground.svg);
  background-size: cover;
  background-position: top;
}


.GameRoundContainer .title {
  font-size: 50px;
  margin: 0;
}

.titleWrapper {
  width: 430px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}


.titleWrapper {
  width: 430px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}


.enterWrapper {
  width: 430px;
  display: flex;
  flex-direction: column;
}


.enterWrapper button {
  width: 430px;
  height: 52px;
  margin: 0px;
  margin-top: 20px;
}


.enterWrapper input {
  width: 415px;
  height: 50px;
  font-size: 24px;
  border-radius: 17px;
  padding-left: 10px;
  border-style: solid;
}


.enterWrapper .text {
  font-size: 20px;
  text-align: left;
}

.countdown{
  margin: 40px;
  margin-top: -40px;
}

.quitContainer {
  position: fixed;
  bottom: 30px;
  display: flex;
  justify-content: center;
  font-size: 22px;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
  color: var(--blue);
}

@media (max-width: 450px) {
  .titleWrapper{
    width: 100%
  }

  .enterWrapper {
    width: 90%;
  }

  .enterWrapper input {
    width: calc(100% - 16px);
  }

  .enterWrapper .text {
    width: 100%;
    font-size: 20px;
    text-align: left;
    padding-top: 0px;
  }

  .enterNameContainer {
    justify-content: center;
  }

  .enterWrapper button {
    width: 100%;
  }
}