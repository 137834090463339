.deletedGameContainer {
    height: calc(100vh - 66.5px);
    width: 100vw;
    background-color: var(--beige);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.deletedGameContainer p {
    color: var(--blue);
    font-size: 20px;
}

.deletedGameContainer .title {
    font-size: 40px;
    margin-top: 66.5px;
}

.deletedGameContainer img {
    padding-top: 100px;
    height: 400px;
    margin: 20px
}