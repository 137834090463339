.resultsContainer {
  height: calc(100vh - 66.5px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../images/NameGameBackground.svg);
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  --width: calc(100vw / 4.4);
}

.resultsContainer .round {
  font-size: 25px;
  font-weight: 500;
  margin-top: 0px;
}

.resultsContainer .finalContainerPhone .round {
  font-size: 25px;
  font-weight: 500;
  margin-top: 5px;
}

.resultsContainer .notFinal {
  height: calc(100vh - 166.5px);
  padding-top: 100px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.resultsContainer .notFinal .title {
  padding-top: 50px;
  padding-bottom: 0px;
}

.resultsContainer .notFinal tbody {
  display: grid;
  row-gap: 5px;
}
.resultsContainer .notFinal tr {
  display: grid;
  height: 30px;
  font-size: 20px;
  text-align: center;
  border-radius: 17px;
  grid: auto / 200px 200px 200px 200px;
}

.resultsContainer .notFinal .tableBody {
  background-color: var(--color);
  color: white;
}

.resultsContainer .notFinal .buttonContainer {
  width: 100vw;
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
}

.resultsContainer .finalContainer {
  height: 100%;
}

.resultsContainer .final {
  height: calc(100% - 100px);
  padding-top: 100px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resultsContainer .final .title {
  padding-top: 50px;
}
.resultsContainer .final tbody {
  display: grid;
  row-gap: 5px;
}
.resultsContainer .final tr {
  display: grid;
  height: 30px;
  font-size: 20px;
  text-align: center;
  border-radius: 17px;
  grid: auto / 200px 200px 200px 200px;
}

.resultsContainer .final .tableBody {
  background-color: var(--color);
  color: white;
}

.resultsContainer .final .buttonContainer {
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.resultsContainer .rarestNameContainer {
  align-items: flex-end;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
}

.resultsContainer .rarestNameContainer .table {
  color: white;
  background-color: #002b5b;
}

.resultsContainer .rarestNameContainer tr {
  display: grid;
  grid: auto / 200px 200px 200px;
}

.resultsContainer .rarestNameContainer .text {
  font-size: 25px;
  padding-right: 50px;
  margin-bottom: 0;
}

.resultsContainer .content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.resultsContainer .finalContainerPhone {
  width: 100%;
}

.resultsContainer .finalContainerPhone tr {
  width: 90vw;
}

.resultsContainer .finalContainerPhone tr {
  height: fit-content;
  grid: auto / var(--width) var(--width) var(--width) var(--width);
}

.resultsContainer .finalContainerPhone td {
  font-size: 1rem;
  margin: 0.8rem;
}

.resultsContainer .finalContainerPhone table {
  margin-bottom: 2vh;
}

.resultsContainer .finalContainerPhone th {
  font-size: 1.3rem;
  margin: 2rem 0.1rem 1rem 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resultsContainer .finalContainerPhone .title {
  padding: 0px;
}

.finalContainerPhone .rarestNameContainerPhone {
  flex-direction: column;
}

.resultsContainer .rarestNameContainerPhone .table {
    color: white;
    background-color: #002b5b;
    margin: 0px;
  }
  

.finalContainerPhone .rarestNameContainerPhone tr {
  display: grid;
  grid: auto / calc(100vw/3.5) calc(100vw/3.5) calc(100vw/3.5);
}

.resultsContainer .rarestNameContainerPhone .text {
  font-size: 25px;
  padding-right: 50px;
  margin-bottom: 0;
}
