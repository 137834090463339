.HomePageMain {
  height: calc(100vh - 106px);
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  background-image: url(../images/HomePageBackground.svg);
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  padding-top: 66.5px;
  overflow-y: hidden;
}

.HomePageMain .text {
  width: 732px;
  position: inherit;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 35px;
  z-index: 2;
}

.HomePageMain .title {
  margin-bottom: 40px;
  font-weight: 500;
}

@media (max-width: 750px) {
  .HomePageMain {
    overflow-y: scroll;
  }

  .title {
    font-size: 34px;
    line-height: 90%;
  }
  .HomePageMain .description {
    line-height: 26px;
    font-size: 18px;
  }
}
