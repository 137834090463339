.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  width: 350px;
  height: 396px;
  border-radius: 17px;
  background-color: var(--white);
  margin: 30px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.3);
}

.card:hover {
  transform: scale(1.02);
  transition-duration: 300ms;
  cursor: pointer;
}

.nameGame {
    background-color: var(--blue);
    background-image: url(../images/TheNameGameImage.svg);
    width: 300px;
    height: 167px;
    align-self: center;
    margin: 30px 30px 10px 30px;
}

.nameSearch {
  background-color: var(--blue);
  background-image: url(../images/NameSearchImage.svg);
  width: 300px;
  height: 167px;
  align-self: center;
  margin: 30px 30px 10px 30px;
}

.gameTitle {
  font-size: xx-large;
  padding: 5px 0px 5px 30px;
  margin: 0px;
}

.description {
  margin: 5px 30px;
  max-height: 175px;
  font-size: 20px;
  overflow-y: hidden;
}
