.enterNameContainer {
  height: calc(100vh - 66.5px);
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../images/NameGameBackground.svg);
  background-size: cover;
  background-position: top;
}

.enterNameWrapper {
  width: 430px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enterNameWrapper button {
  width: 102%;
  height: 52px;
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
}

.enterNameWrapper input {
  width: 422px;
  height: 50px;
  font-size: 24px;
  border-radius: 17px;
  padding: 0px;
  padding-left: 10px;
  border-style: solid;
}

.enterNameWrapper .text {
  font-size: 20px;
  text-align: left;
  padding-top: 250px;
}

.validCodeContainer {
  height: 24px;
  font-size: 24px;
  margin: 10px;
  color: var(--red);
}

@media (max-width: 450px) {
  .enterNameWrapper {
    width: 90%;
  }

  .enterNameWrapper input {
    width: calc(99% - 10px);
  }

  .enterNameWrapper .text {
    width: 100%;
    font-size: 20px;
    text-align: left;
    padding-top: 0px;
  }

  .enterNameContainer {
    justify-content: center;
  }
}
