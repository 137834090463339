:root {
  --red: #ea5455;
  --blue: #002b5b;
  --beige: #FFFBF1;
  --white: #fbf9f6;
  --black: #00000000;
  --color: #002b5b;
}

body {
  margin: 0;
  overflow-x:hidden;
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-feature-settings: "liga" 1, "calt" 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.blueButton {
  position: inherit;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: var(--blue);
  border-radius: 10ch;
  border-style: none;
  margin: 40px;
  width: 256.8px;
  min-height: 62.4px;
  z-index: 100;
  color: #fff;
  font-family:  Inter, sans-serif;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.blueButton:hover {
  text-decoration-line: underline;
  text-underline-offset: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.title {
  font-size: 64px;
  font-weight: 400;
  z-index: 10;
}

.nameSearchView {
  min-height: calc(100vh - 66.5px);
  background: url(./images/NameGameBackground.svg);
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}