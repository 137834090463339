.podiumContainer {
  height: calc(100vh - 66.5px);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--beige);
  flex-direction: column;
}

.podiumContainer div {
    justify-content: flex-end;
}

.podiumContainer .title {
  color: #002b5b;
  text-align: center;
  font-size: 90px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
}

.podiumContainer .blueButton {
  width: 276px;
  max-height: 42px;
}

.podiumContainer .name {
  color: #002b5b;
  text-align: center;
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
}

.podiumContainer .placements {
  display: flex;
  flex-direction: row;
  color: var(--beige);
  align-items: flex-end;
  text-align: center;
  font-size: 90px;
  font-weight: 600;
  justify-content: center;
}

.podiumContainer .step {
  width: 176px;
  margin: 4px;
  background: #ea5455;
  justify-content: flex-start;
}

.podiumContainer .base {
  width: 600px;
  min-height: 29px;
  margin: 4px;
  background: #ea5455;
}

@media (max-width: 500px) {
      
      .podiumContainer .title {
        color: #002b5b;
        text-align: center;
        font-size: 65px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 20px;
      }
      
      .podiumContainer .blueButton {
        width: 276px;
        max-height: 42px;
      }
      
      .podiumContainer .name {
        color: #002b5b;
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      
      .placements {
        display: flex;
        flex-direction: row;
        color: var(--beige);
        align-items: flex-end;
        text-align: center;
        font-size: 65px;
        font-weight: 600;
      }
      
      .step {
        width: 75px;
        margin: 4px;
        background: #ea5455;
      }
      
      .base {
        min-height: 29px;
        margin: 4px;
        background: #ea5455;
      }
      
}