.signInContainer {
    height: calc(100vh - 106px);
    width: 100vw;
    padding-top: 66.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(../images/NameGameBackground.svg);
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
    overflow-x: hidden;
}

.signInContainer .blueButton {
    position: fixed;
    top: 80px;
    right: 1vw;
    margin: 12px;
    width: max-content;
    padding: 0px 15px;
}

.signInContainer .topContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.signInContainers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.userInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}

.userInfoContainer .userInfo {
    width: 732px;
    position: inherit;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px;
    z-index: 2;
}

.signInContainer .title {
    font-size: 4em;
    margin: 0;
    padding: 20px 50px;
    text-align: center;
}

.signInContainer .title p {
    margin: 0px
}

.signInContainerGoogle h1 {
    margin-bottom: 1em;
    font-weight: 300;
}

.signInContainerGoogle p {
    font-size: 1.2em;
    margin-bottom: 2em;
}

.signInContainerGoogle {
    color: white;
    margin-top: 2em;
    background-color: #de5b5b;
    padding: 1em;
    border-radius: 1em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.buttonContainer {
    padding: 0em 4em 1em 4em;

}

body .signInContainers {
    height: calc(100vh - 66.5px);
    margin: 0;
    padding: 0;
    background: url(../images/NameGameBackground.svg) top / cover fixed;
}

@media (max-height: 500px) {
    .signInContainer .title {
        padding-top: 0px;
        margin-bottom: 40px;
    }
}

@media (max-width: 1000px) {
    .signInContainer .title {
        font-size: 3rem;
        max-width: 100vw;
    }

    .userInfoContainer .userInfo {
        width: 80vw;
    }
}

@media (max-width: 500px) {
    .signInContainer .title {
        font-size: 2.5rem;
    }

    .signInContainer .title p {
        margin-top: 10px;
    }

    .userInfoContainer .userInfo {
        font-size: 20px;
    }
}