.nameInfoContainer {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.nameStatsContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.nameData{
    width: 80vw;
    white-space: pre-line;
    padding: 20px 40px;
    margin-bottom: 30px;
    background: rgba(249, 249, 249, 0.9);
    border-radius: 15px; 
}

.nameData .title{
    font-size: 23px;
    font-weight: 700;
    text-align: left;
}

.nameData .content{
    padding: 20px;
    font-size: 18px;
}

@media (max-height: 500px) {
    .nameInfoContainer .title {
        padding-top: 0px;
        margin-bottom: 40px;
    }
}

@media (max-width: 600px) {
    .nameData {
        padding: 10px 20px;
    }

    .nameData .title{
        font-size: 18px;
    }

    .nameData .content{
        padding: 5px;
        font-size: 15px;
    }
}