.desktopContainer {
  height: calc(100vh - 66.5px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../images/NameGameBackground.svg);
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}

.phoneViewContainer {
  min-height: calc(100vh - 66.5px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  background: url(../images/NameGameBackground.svg);
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}

.phoneViewContainer .scrollForHelp {
  position: absolute;
  bottom: 0;
  font-weight: 600;
}

.phoneViewContainer .buttonContainer {
  min-height: calc(100vh - 66.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phoneViewContainer .blueButton {
  margin: 10px;
}

.phoneViewContainer .title {
  padding-top: 0px;
}

.phoneViewContainer .helpTextPhone {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: min(1rem, 20px);
  margin-bottom: 20px;
  font-size: 0.8rem;
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5em;
  transition: transform 200ms ease;
  transform-origin: 92% -10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 10px;
}

.desktopContainer .blueButton {
  margin: 12px;
}

.desktopContainer .title {
  padding-top: 10vh;
  margin-bottom: 40px;
}

.desktopContainer .infoBox {
  position: relative;
  width: 50px;
  top: 30px;
  left: calc(100vw / 2 - 50px);
  cursor: pointer;
}

h2 {
  margin: 0px;
}

.desktopContainer .helpText {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 120px;
  right: 0.3%;
  width: 80ch;
  margin: min(1.2rem, 22px);
  font-size: 0.8rem;
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5em;
  z-index: 42;
  transform: scale(0);
  transition: transform 200ms ease;
  transform-origin: 96% -10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.desktopContainer .helpText::before {
  content: "";
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  top: -9.7px;
  right: 20px;
}

.infoBox:hover + .helpText,
.infoBox:focus + .helpText,
.helpText:hover {
  transform: scale(1);
  z-index: 1000;
}

@media (max-height: 500px) {
  .desktopContainer .title {
    padding-top: 0px;
    margin-bottom: 40px;
  }
}
