.GamesViewMain {
  min-height: calc(100vh - 66.5px);
  height: max-content;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../images/GamesPageBackground.svg);
  background-size: cover;
  background-position: top;
  z-index: 3;
}

.GamesViewMain .title {
  z-index: 3;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 1;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  z-index: 2;
}

@media (max-width: 800px) {
  .cardContainer {
    flex-direction: column;
  }
}
