.header {
  background-color: var(--beige);
  height: fit-content;
  min-height: 53px;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.25);
}

.header button {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  line-height: normal;
  width: 100px;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
}

.header button:hover {
  text-decoration-line: underline;
}

.leftSpace {
  width: 100px;
}

@media (max-width: 550px) {
  .header button {
    font-size: 15px;
    width: 80px;
  }

  .leftSpace {
    width: 80px;
  }
}

@media (max-width: 450px) {
  .header button {
    font-size: 15px;
    width: 80px;
  }

  .leftSpace {
    display: none;
  }
}