.basicLoaderContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--none);
  flex-direction: column;
}

.basicLoaderContainer .loader {
  width: 100px;
  height: 100px;
  position: relative;
  animation: rotate 2.5s ease-in infinite alternate;
}
.basicLoaderContainer .loader::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  color: var(--red);
  background: currentColor;
  width: 100px;
  height: 50px;
  border-radius: 0 0 100px 100px;
}
.basicLoaderContainer .loader::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 10%;
  background: #ae2d2e;
  width: 12px;
  height: 100px;
  animation: rotate 2s linear infinite alternate-reverse;
}

.basicLoaderContainer .title2 {
  font-size: 18px;
  font-weight: 400;
  z-index: 10;
  margin-left: 10px;
  margin-bottom: 20px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
