.lobbyContainer {
  height: calc(100vh - 66.5px);
  display: flex;
  max-width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: url(../images/LobbyBackground.svg);
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}

.lobbyContainer .code {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 90px;
  font-weight: 700;
}

.lobbyContainer .title2 {
  font-size: 50px;
  font-weight: 600;
}

.lobbyContainer .title3 {
  font-size: 40px;
  text-decoration-line: underline;
}

.lobbyContainer .players2 {
  display: flex;
  height: 100px;
  flex-wrap: wrap;
  flex-basis: 100px;
  justify-content: center;
  max-height: 100px;
  overflow: visible;
}

.lobbyContainer .players2 p {
  height: fit-content;
  font-size: 26px;
  margin: 10px;
}

.lobbyContainer .players1 {
  display: grid;
  max-width: 100vw;
  grid-template-columns: repeat(5, 1fr);
  height: 100px;
  flex-wrap: wrap;
  flex-basis: 100px;
  justify-content: center;
  max-height: 100px;
  overflow: visible;
}

.lobbyContainer .players1 p {
  height: fit-content;
  font-size: 20px;
  margin: 10px;
}

@media (max-width: 550px) {
  .lobbyContainer {
    min-height: calc(100vh - 66.5px);
    height: max-content;
    padding-top: 0px;
  }

  .lobbyContainer .blueButton {
    margin-bottom: 20px;
  }

  .lobbyContainer .players1 {
    grid-template-columns: repeat(3, 1fr);
  }
}
