.LoaderContainer {
  height: calc(100vh - 66.5px);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--beige);
  flex-direction: column;
}

.loader {
  width: 200px;
  height: 200px;
  position: relative;
  animation: rotate 2.5s ease-in infinite alternate;
}
.loader::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  color: var(--red);
  background: currentColor;
  width: 200px;
  height: 100px;
  border-radius: 0 0 100px 100px;
}
.loader::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 10%;
  background: #ae2d2e;
  width: 25px;
  height: 200px;
  animation: rotate 2s linear infinite alternate-reverse;
}

.LoaderContainer .title {
  font-size: 64px;
  font-weight: 400;
  z-index: 10;
  padding-bottom: 50px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 800px) {
  .LoaderContainer .title {
    font-size: 50px;
  }
}