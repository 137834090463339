.nameSearchContainer {
  height: calc(20vh);
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;

}

.nameSearchContainer .blueButton {
  margin: 0px;
  margin-left: 12px;
  width: 170px;
  height: 62px;
}

.nameSearchForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nameSearchInput {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 0px;
  font-size: 25px;
  font-family: "Roboto", sans-serif;
  color: #333;
  outline: none;
  text-align: center;
}

.nameSearchContainer input:focus::placeholder {
  color: transparent;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  padding: 10px 0px 16px 0px
}

@media (max-height: 500px) {
  .nameSearchContainer .title {
    padding-top: 0px;
    margin-bottom: 40px;
  }
}

@media (max-width: 600px) {
    .nameSearchContainer .blueButton {
        width: 320px;
        margin: 0px;
    }
  }
  